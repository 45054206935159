<template>
  <notification-item :message="message" @delete-item="deleteItem" @close="close">
    <template v-slot:title>
      <span>{{ $t('_.title') }}</span>
    </template>
    <div class="room-box" v-if="showRoomBox">
      <p class="main-msg">{{ $t('_.joinRoom') }}</p>
      <p v-html="$t('_.roomTitle', {name: message.room.name})" />
      <p v-html="$t('_.roomPassword', {password: message.room.password})" />
    </div>
    <p>{{ $t('_.statusMessage') }}</p>
    <template v-slot:buttons>
      <button v-if="!isPassed" @click="goLobby">{{ $t(`_.${realTime ? 'realTimeButton' : 'button'}`) }}</button>
    </template>
  </notification-item>
</template>

<script>
import Specific from '@shared/types/Specific';
import { isPassed } from '@shared/utils/timeUtils';
import { NOTIFICATION_ROOM_TYPE_GAMES } from '@shared/data/constants/notification';
import NotificationItem from '@shared/components/notification/NotificationItem.vue';

export default {
  name: 'MatchEndItem',
  components: { NotificationItem },
  lexicon: 'shared.notification.MATCH_END',
  props: {
    message: Specific,
  },
  computed: {
    matchData() {
      return this.message?.data?.match || {};
    },
    application() {
      return this.message?.data?.application || {};
    },
    tournament() {
      return this.message?.data?.tournament || {};
    },
    room() {
      return this.matchData?.room || {};
    },
    isLeader() {
      const leaderIds = this.application.leaderIds || [];
      const userId = this.message?.userId;
      return leaderIds.includes(userId);
    },
    showRoomBox() {
      return this.matchData.bracket !== 'BR' && this.message.room;
    },
    waitingEnd() {
      const { room } = this;
      const match = this.matchData;
      const arena = this.tournament;
      return NOTIFICATION_ROOM_TYPE_GAMES.includes(arena.gameId) ? room.waitingEnd : match.waitingEnd;
    },
    isPassed() {
      return this.waitingEnd ? isPassed(this.$store, this.waitingEnd) : true;
    },
    realTime() {
      return Boolean(this.message?.realTime);
    },
  },
  methods: {
    goLobby() {
      const { tournamentId, gameId, isWcgArena } = this.tournament;
      const { stageId } = this.matchData;
      const { matchId } = this.matchData;

      this.routeMainPath({ path: `${this.$i18n('path')}/arena/${isWcgArena ? 'wcg/' : ''}${tournamentId || gameId}/bracket?checkin=true&openMatch=${matchId}&view=lobby&stage-id=${stageId}&round=all` });
      this.$emit('read-item', this.message || {});
    },
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
